<template>
    <h2>General</h2>
    <p>
      This is a fact checking engine for my semantic web course in university. It takes a dataset of rdf statements and returns a rdf file with the facts and the corresponding truth value (0 to 1).
    </p>
    <p>
      The engine is written in Java.
    </p>
  </template>
  
  <script>
  export default {
    name: 'FactCheckingEngine',
  }
  </script>
  
  <style scoped></style>