<template>
  <p>
    This is my personal homepage. It is a simple Vue.js application that I use to provide some information about me and
    to showcase my projects.
    It is a fun project for me and I am adding new features to it from time to time. I don't have much experience with
    web development, so I am learning a lot while working on this project and trying out a bunch of new things.
  </p>
  <p>
    The homepage is written in JavaScript using the Vue.js framework.
  </p>
</template>

<script>
export default {
  name: 'Homepage',
}
</script>

<style scoped></style>