<template>
  <h2>General</h2>
  <p>
    This is a basic Discord music bot that can play music from YouTube and other sources. It has the basic functionality
    of a music bot.
    It is more of a fun project for me, so I am currently not planning on adding much more features, however I am open
    to suggestions and might add more features in the future.
  </p>
  <p>
    The bot is written in JavaScript and uses the discord.js and discord-player library. It can either be run using
    Node.js or as a Docker container.
  </p>
</template>

<script>
export default {
  name: 'MusicBot',
}
</script>

<style scoped></style>